@import url("https://fonts.googleapis.com/css2?family=Agbalumo&display=swap");
@font-face {
  font-family: "Comic Sans MS";
  src: url("assets/fonts/Comic\ Sans\ MS.ttf");
}
@font-face {
  font-family: "Super Comic";
  src: url("assets/fonts/Super\ Comic.otf");
}

// Reset css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Agbalumo", sans-serif;
  font-size: 16px;
  background: #58291b;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}

.App {
  overflow: hidden;
  .container {
    margin: 0 auto;
    overflow: hidden;
    &.wrapper {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
  .page-header {
    .container {
      padding-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .img-avatar {
        width: 128px;
        border: 4px solid #d77e63;
        border-radius: 64px;
        overflow: hidden;
        @media only screen and (max-width: 500px) {
          width: 90px;
        }
        @media only screen and (max-width: 480px) {
          width: 60px;
        }
      }
      .socials {
        display: flex;
        gap: 46px;
        @media only screen and (max-width: 480px) {
          gap: 24px;
        }
        li {
          width: 47px;
          @media only screen and (max-width: 480px) {
            width: 35px;
          }
        }
      }
    }
  }
  .page-main {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .page-title {
        font-size: 128px;
        font-weight: 400;
        line-height: 189.44px;
        text-align: center;
        color: #d77e63;
        @media only screen and (max-width: 690px) {
          font-size: 90px;
          line-height: 120px;
        }
        @media only screen and (max-width: 500px) {
          font-size: 64px;
          line-height: 80px;
        }
      }
      .des {
        font-family: "Comic Sans MS";
        font-size: 48px;
        font-weight: 400;
        line-height: 66.89px;
        text-align: center;
        color: #d77e63;
        @media only screen and (max-width: 690px) {
          font-size: 32px;
          line-height: 48px;
        }
        @media only screen and (max-width: 500px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .img-main {
        margin-top: 50px;
        border: 8px solid #d77e63;
        overflow: hidden;
        border-radius: 128px;
      }
      .tools {
        margin-top: 73px;
        display: flex;
        align-items: center;
        gap: 24px;
        font-family: "Comic Sans MS";
        font-size: 32px;
        font-weight: 400;
        line-height: 44.59px;
        text-align: center;
        color: #d77e63;
        text-decoration: underline;
        @media only screen and (max-width: 480px) {
          margin-top: 18px;
          font-size: 24px;
        }
      }
      .btn-buy {
        background: #d77e63;
        border-radius: 12px;
        padding: 18px 20px;
        font-family: "Super Comic";
        font-size: 32px;
        font-weight: 400;
        line-height: 38.4px;
        text-align: center;
        text-transform: uppercase;
        margin-top: 40px;
        @media only screen and (max-width: 480px) {
          font-size: 28px;
          line-height: normal;
          margin-top: 18px;
        }
      }
      .address {
        font-family: "Comic Sans MS";
        font-size: 36px;
        font-weight: 400;
        line-height: 50.17px;
        text-align: center;
        color: #d77e63;
        margin-top: 40px;
        @media only screen and (max-width: 1130px) {
          font-size: 32px;
          line-height: normal;
          margin-top: 24px;
        }
        @media only screen and (max-width: 900px) {
          font-size: 28px;
          line-height: normal;
          margin-top: 18px;
        }
        @media only screen and (max-width: 796px) {
          font-size: 18px;
          word-break: break-all;
          white-space: normal;
          width: 100%;
        }
      }
    }
  }
  .page-footer {
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 111px 24px 45px;
      @media only screen and (max-width: 824px) {
        flex-direction: column;
        gap: 12px;
        padding: 48px 24px;
      }
      p {
        font-family: "Comic Sans MS";
        font-size: 24px;
        font-weight: 400;
        line-height: 33.45px;
        color: #d77e63;
        @media only screen and (max-width: 1124px) {
          font-size: 18px;
          line-height: normal;
        }
        @media only screen and (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }
}
